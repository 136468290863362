<template>
  <div class="page" v-loading="loading">
    <img class="header" src="@image/kgjc-.jpg" alt="个人主页背景图" />
    <header>
      <section>
        <div class="avatar">
          <img
            class=""
            :src="
              userInfo.avatar ? userInfo.avatar : require('@image/default.png')
            "
            alt="个人头像"
          />
          <img
            v-if="userInfo.company && userInfo.company.status == 1"
            class="jia_v"
            :src="$http.baseURL + '/static/images/web/63a5d0b53e8651b8.png'"
          />
        </div>

        <div class="info">
          <div class="name">
            <span>{{ userInfo.nickname }}</span>
            <el-button v-if="userInfo.company">{{
              userInfo.company.title_name
            }}</el-button>
          </div>
          <span v-if="userInfo.company">{{
            userInfo.company.department_name
          }}</span>
          <span v-if="userInfo.company">{{ userInfo.company.company }}</span>
        </div>
        <div class="btn">
          <el-button @click="xin.goUrl('/setting')" class="edit"
            >编辑资料</el-button
          >
          <div @click="xin.goUrl('/authentication')">
            <div
              v-if="userInfo.company && userInfo.company.status < 1"
              class="hui-btn"
            >
              审核中
            </div>
            <div
              v-else-if="userInfo.company && userInfo.company.status == 1"
              class="hui-btn"
            >
              已认证
            </div>
            <div
              v-else-if="userInfo.company && userInfo.company.status == 2"
              class="hui-btn"
            >
              未通过
            </div>
            <el-button v-else class="authenticate">身份认证</el-button>
          </div>
        </div>
      </section>
    </header>
    <!-- 个人简介 -->
    <section>
      <header>
        <span class="title">个人简介</span>
        <i
          class="iconfont bianji"
          title="修改个人简介"
          v-show="!edit.synopsis"
          @click="setTex(1)"
        ></i>
      </header>
      <el-input
        autocomplete="off"
        v-if="edit.synopsis"
        class="edit"
        type="textarea"
        :rows="4"
        placeholder="请输入内容"
        v-model="setform.synopsis"
      >
      </el-input>
      <el-input
        autocomplete="off"
        v-else
        class="read"
        resize="none"
        type="textarea"
        :rows="4"
        readonly
        placeholder="您还没自己的简介哦~快去编辑吧"
        v-model="setform.synopsis"
      >
      </el-input>
      <div class="btn" v-show="edit.synopsis">
        <el-button
          class="cancel"
          @click="
            setform.synopsis = setform.raw_synopsis;
            edit.synopsis = false;
          "
          >取消</el-button
        >
        <el-button class="confirm" @click="postUpdateSynopsis(1)"
          >确定</el-button
        >
      </div>
    </section>
    <!-- 个人专长 -->
    <section>
      <header>
        <span class="title">个人专长</span>
        <i
          class="iconfont bianji"
          title="修改个人专长"
          v-show="!edit.expertise"
          @click="setTex(2)"
        ></i>
      </header>
      <template v-if="edit.expertise">
        <el-input
          autocomplete="off"
          class="edit"
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="setform.expertise"
        >
        </el-input>
        <div class="btn">
          <el-button
            class="cancel"
            @click="
              setform.expertise = setform.raw_expertise;
              edit.expertise = false;
            "
            >取消</el-button
          >
          <el-button class="confirm" @click="postUpdateSynopsis(2)"
            >确定</el-button
          >
        </div>
      </template>
      <template v-else>
        <el-input
          autocomplete="off"
          class="read"
          resize="none"
          type="textarea"
          :rows="4"
          readonly
          placeholder="您还没自己的专长哦~快去编辑吧"
          v-model="setform.expertise"
        >
        </el-input>
      </template>
    </section>
    <!-- 职业信息 -->
    <section>
      <header>
        <span class="title">职业信息</span>
        <i
          class="iconfont xinzeng2"
          title="添加职业信息"
          v-if="!edit.jobInformation"
          @click="
            formDefault();
            edit.jobInformation = true;
          "
        ></i>
      </header>
      <template v-if="edit.jobInformation">
        <div class="add">
          <el-date-picker
            ref="datePicker"
            v-model="timeNde"
            value-format="yyyy-MM-dd"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="入职时间"
            end-placeholder="截止时间"
            :picker-options="pickerOptions"
            @change="changeTime"
            :append-to-body="false"
          >
          </el-date-picker>
          <el-input
            autocomplete="off"
            v-model="form.name"
            placeholder="单位名称"
          ></el-input>
          <el-input
            autocomplete="off"
            v-model="form.platform"
            placeholder="科室"
          ></el-input>
          <!-- <el-cascader ref="cascaderAddr" v-model="keshi" placeholder="选择科室" :options="departmentList" :props="{ expandTrigger: 'hover' }" @change="handleChange" ></el-cascader> -->
          <el-button class="cancel" @click="edit.jobInformation = false"
            >取消</el-button
          >
          <el-button class="confirm" @click="postUpdateAddMore(1)"
            >保存</el-button
          >
        </div>
      </template>
      <div class="textList">
        <div class="list" v-for="item in userInfo[1]" :key="item.id">
          <span
            >{{ item.start_date }} ~ {{ item.end_date }} {{ item.name }}
            {{ item.platform }}</span
          >
          <div class="right-icon">
            <i
              title="修改职业信息"
              @click="
                setOccupation(item);
                edit.jobInformation = true;
              "
              class="iconfont bianji5"
            ></i>
            <i
              title="删除职业信息"
              @click="postUuserDeleteMore(item)"
              class="iconfont shanchu5"
            ></i>
          </div>
        </div>
        <div v-if="!userInfo[1]" class="list-ko">
          <span>您当前职业信息为空!</span>
        </div>
      </div>
    </section>
    <!-- 工作贡献 -->
    <section>
      <header>
        <span class="title">诊疗数量</span>
      </header>
      <div class="textList">
        <span class="list2" v-if="userInfo.info">
          诊量：{{ userInfo.info.consultation_number }}
          <span style="margin-left: 30px"
            >手术量：{{ userInfo.info.operation_number }}
          </span>
        </span>
      </div>
    </section>
    <!-- 科研贡献 -->
    <section>
      <header>
        <span class="title">科研贡献</span>
        <i
          class="iconfont xinzeng2"
          title="添加科研贡献"
          v-if="!edit.scientificResearchContribution"
          @click="
            formDefault();
            edit.scientificResearchContribution = true;
          "
        ></i>
      </header>
      <div class="add" v-show="edit.scientificResearchContribution">
        <el-date-picker
          v-model="form.start_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :append-to-body="false"
        >
        </el-date-picker>
        <el-input
          autocomplete="off"
          v-model="form.name"
          placeholder="请输入标题"
        ></el-input>
        <el-button
          class="cancel"
          @click="edit.scientificResearchContribution = false"
          >取消</el-button
        >
        <el-button class="confirm" @click="postUpdateAddMore(2)"
          >保存</el-button
        >
      </div>
      <div class="textList">
        <div class="list" v-for="item in userInfo[2]" :key="item.id">
          <span> {{ item.start_date }} {{ item.name }} </span>
          <div class="right-icon">
            <i
              title="修改科研贡献"
              @click="
                setOccupation(item);
                edit.scientificResearchContribution = true;
              "
              class="iconfont bianji5"
            ></i>
            <i
              title="删除科研贡献"
              @click="postUuserDeleteMore(item)"
              class="iconfont shanchu5"
            ></i>
          </div>
        </div>
        <div v-if="!userInfo[2]" class="list-ko">您当前科研贡献为空</div>
      </div>
    </section>
    <!-- 学术论文 -->
    <section>
      <header>
        <span class="title">学术论文</span>
        <i
          class="iconfont xinzeng2"
          v-if="!edit.academicPapers"
          @click="
            formDefault();
            edit.academicPapers = true;
          "
        ></i>
      </header>
      <template v-if="edit.academicPapers">
        <div class="add">
          <el-input
            autocomplete="off"
            v-model="form.name"
            placeholder="请输入标题"
          ></el-input>
          <el-date-picker
            v-model="form.start_date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="发表时间"
            :append-to-body="false"
          >
          </el-date-picker>
          <el-input
            autocomplete="off"
            v-model="form.platform"
            placeholder="发表的平台"
          ></el-input>
          <el-button class="cancel" @click="edit.academicPapers = false"
            >取消</el-button
          >
          <el-button class="confirm" @click="postUpdateAddMore(3)"
            >保存</el-button
          >
        </div>
      </template>
      <div class="textList">
        <div class="list" v-for="item in userInfo[3]" :key="item.id">
          <div>
            <div>{{ item.name }}</div>
            <div class="remark">{{ item.start_date }} {{ item.platform }}</div>
          </div>
          <div class="right-icon">
            <i
              title="修改科研贡献"
              @click="
                setOccupation(item);
                edit.academicPapers = true;
              "
              class="iconfont bianji5"
            ></i>
            <i
              title="删除科研贡献"
              @click="postUuserDeleteMore(item)"
              class="iconfont shanchu5"
            ></i>
          </div>
        </div>
        <div v-if="!userInfo[3]" class="list-ko">您当前学术论文为空</div>
      </div>
    </section>
    <!-- 医学贡献 -->
    <section>
      <header>
        <span class="title">医学贡献</span>
      </header>
      <template v-if="edit.scienceContribution"> </template>
      <div class="textList">
        <span class="list2">
          <!-- 医学贡献：{{
            userInfo.science_terms_count + userInfo.science_video_count
          }}条  -->
          医学贡献 ({{ fensum }}) 分
        </span>
      </div>
      <el-collapse>
        <!-- 病例分享 -->
        <el-collapse-item :title="fen3">
          <div
            v-for="item in userInfo.science_case"
            style="margin-top: 5px"
            :key="item.id"
          >
            {{ item.title }} {{ item.create_time | timeFormat("yyyy-mm-dd") }}
          </div>
        </el-collapse-item>

        <!-- 问答 -->
        <el-collapse-item :title="fen4">
          <div
            v-for="item in userInfo.science_problem"
            style="margin-top: 5px"
            :key="item.id"
          >
            {{ item.title }} {{ item.create_time | timeFormat("yyyy-mm-dd") }}
          </div>
        </el-collapse-item>

        <!-- 说说 -->
        <!-- <el-collapse-item :title="fen5">
          <div
            v-for="item in userInfo.science_say"
            style="margin-top: 5px"
            :key="item.id"
          >
            {{ item.title }} {{ item.create_time | timeFormat("yyyy-mm-dd") }}
          </div>
        </el-collapse-item> -->

        <!-- 学术视频 -->
        <el-collapse-item :title="fen1">
          <div v-for="item in userInfo.science_video" :key="item.id">
            {{ item.title }} {{ item.create_time | timeFormat("yyyy-mm-dd") }}
          </div>
        </el-collapse-item>

        <!-- 指南解析 -->
        <el-collapse-item :title="fen2">
          <div
            v-for="item in userInfo.science_terms"
            style="margin-top: 5px"
            :key="item.id"
          >
            {{ item.title }} {{ item.create_time | timeFormat("yyyy-mm-dd") }}
          </div>
        </el-collapse-item>

        <!-- 发布论文 -->
        <el-collapse-item :title="fen6">
          <div
            v-for="item in userInfo.science_paper"
            style="margin-top: 5px"
            :key="item.id"
          >
            {{ item.title }} {{ item.create_time | timeFormat("yyyy-mm-dd") }}
          </div>
        </el-collapse-item>
      </el-collapse>
    </section>
    <!-- 公益活动 -->
    <section>
      <header>
        <span class="title">公益活动</span>
        <i
          class="iconfont xinzeng2"
          v-show="!edit.charitableActivities"
          @click="
            formDefault();
            edit.charitableActivities = true;
          "
        ></i>
      </header>
      <div v-show="edit.charitableActivities" class="add">
        <el-date-picker
          v-model="form.start_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="活动时间"
          :append-to-body="false"
        >
        </el-date-picker>
        <el-input
          autocomplete="off"
          v-model="form.name"
          placeholder="活动名称"
        ></el-input>
        <el-input
          autocomplete="off"
          v-model="form.platform"
          placeholder="活动主办方"
        ></el-input>

        <el-image
          v-if="form.images"
          :src="form.images"
          class="avatar"
          :preview-src-list="[form.images]"
        >
        </el-image>
        <i v-else class="el-icon-plus avatar-uploader-icon">
          <input @change="uploadImg" ref="avatarInput" type="file" />
        </i>

        <el-button class="cancel" @click="edit.charitableActivities = false"
          >取消</el-button
        >
        <el-button class="confirm" @click="postUpdateAddMore(4)"
          >保存</el-button
        >
      </div>
      <div class="textList">
        <div class="list" v-for="item in userInfo[4]" :key="item.id">
          <div class="list-comuu">
            <div class="date">
              <span class="month">{{
                item.start_date | timeFormat("mm-dd")
              }}</span>
              <span class="year">{{
                item.start_date | timeFormat("yyyy")
              }}</span>
            </div>
            <img v-if="item.images" :src="item.images" alt="图片" />
            <div class="content">
              <span class="title">{{ item.name }}</span>
              <span class="sponsor">{{ item.platform }}</span>
            </div>
          </div>
          <div class="right-icon">
            <i
              title="修改科研贡献"
              @click="
                setOccupation(item);
                edit.charitableActivities = true;
              "
              class="iconfont bianji5"
            ></i>
            <i
              title="删除科研贡献"
              @click="postUuserDeleteMore(item)"
              class="iconfont shanchu5"
            ></i>
          </div>
        </div>
        <div v-if="!userInfo[4]" class="list-ko">您当前公益活动为空</div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      timeNde: "", //范围时间选择
      keshi: "",
      setform: {
        expertise: "", //个人简介
        raw_expertise: "",
        synopsis: "", //个人专业
        raw_synopsis: "",
      },
      form: {
        start_date: "", //开始时间
        end_date: "", //结束时间
        name: "", //内容
        department_one_id: 0,
        department_two_id: 0,
        platform: "",
        images: "",
      },
      loading: false,
      edit: {
        expertise: false,
        synopsis: false,
        jobInformation: false,
        scientificResearchContribution: false,
        academicPapers: false,
        charitableActivities: false,
      },
      fen1: "学术视频（0）分",
      fen2: "指南解析（0）分",
      fen3: "病例分享（0）分",
      fen4: "问答(0)分",
      // fen5: "说说(0)条",
      fen6: "发布论文(0)分",
      fensum: 0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      jobInformation: {
        date: "",
        companyName: null,
        department: null,
      },
      departmentList: [], //科室
      scientificResearchContribution: {
        date: "",
        title: null,
      },
      academicPapers: {
        title: null,
        date: "",
        platform: null,
      },
      charitableActivities: {
        title: null,
        date: "",
        sponsor: null,
        image: "",
      },
    };
  },
  mounted() {
    this.setInfo();
    this.getDocterDepartment();
    this.getUserInfo();
  },
  methods: {
    ...mapActions("userManagement", ["getUserInfo"]),
    async uploadImg() {
      //图片上传
      let files = this.$refs.avatarInput.files;
      if (!files.length) {
        return;
      }
      let formData = new FormData();
      formData.append("files", files[files.length - 1]);
      this.xin.isLoadding(true);
      let { code, data } = await this.$userApi.postFilesUpload(formData);
      this.xin.isLoadding();
      if (code == 200) {
        this.xin.message("上传成功");
        this.form.images = data;
      }
    },
    // 修改信息
    setOccupation(item) {
      //修改职业信息前置
      this.form = Object.assign({}, item);
      if (item.end_date == "至今") {
        let currentDate = this.getCurrentDate();
        this.timeNde = [item.start_date, currentDate];
      } else {
        this.timeNde = [item.start_date, item.end_date];
      }
      this.keshi = [item.department_one_id, item.department_two_id];
    },
    formDefault() {
      this.edit = this.$options.data().edit;
      Object.assign(this.$data.form, this.$options.data().form);
      this.timeNde = ""; //范围时间选择
      this.keshi = "";
    },
    async postUuserDeleteMore(item) {
      //删除
      this.$confirm("确定要删除该条信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          this.loading = true;
          let { code } = await this.$userApi.postUuserDeleteMore({
            id: item.id,
          });
          if (code == 200) {
            await this.getUserInfo();
            this.$message({ message: "删除成功", type: "success" });
          }
          this.loading = false;
        })
        .catch((e) => e);
    },
    getDocterDepartment() {
      //获取科室数据
      this.$newsApi.getDocterDepartment().then((res) => {
        if (res.code == 200) {
          res.data.forEach((v) => {
            v.value = v.id;
            v.label = v.name;
            v.children.forEach((v2) => {
              v2.value = v2.id;
              v2.label = v2.name;
              v2.children = null;
            });
          });
          this.departmentList = res.data;
        }
      });
    },
    // 保存信息
    async postUpdateAddMore(type) {
      //添加职业信息
      if (!this.verification(type)) return;
      let form = Object.assign({}, this.form);
      form.type = type;
      if (form.id) {
        //修改职业信息
        this.loading = true;
        let { code, data } = await this.$userApi.postUuserUpdateMore(form);
        if (code == 200) {
          await this.getUserInfo();
          this.xin.message("修改成功");
          Object.assign(this.$data.edit, this.$options.data().edit);
        }
        this.loading = false;
        return;
      }
      this.loading = true;
      let { code } = await this.$userApi.postUpdateAddMore(form);
      if (code == 200) {
        await this.getUserInfo();
        this.xin.message("添加成功");
        Object.assign(this.$data.edit, this.$options.data().edit);
      }
      this.loading = false;
    },
    verification(type) {
      let locs = false;
      switch (Number(type)) {
        case 1:
          locs = this.verifiOccup();
          break;
        case 2:
          locs = this.verifiScientific();
          break;
        case 3:
          locs = this.verifiPaper();
          break;
        case 4:
          locs = this.verifiPublic();
          break;
      }
      return locs;
    },
    verifiOccup() {
      //职业
      let { start_date, name, platform } = this.form;
      if (!start_date) {
        this.$message.error("请选择时间");
      } else if (!name) {
        this.$message.error("请输入单位名称");
      } else if (!platform) {
        this.$message.error("请选择科室");
      } else {
        this.form.department_one_id = this.keshi[0];
        this.form.department_two_id = this.keshi[1];
        return true;
      }
      return false;
    },
    verifiScientific() {
      //科研
      let { start_date, name, platform } = this.form;
      if (!start_date) {
        this.$message.error("请选择时间");
      } else if (!name) {
        this.$message.error("请输入标题");
      } else return true;
      return false;
    },
    verifiPaper() {
      //论文
      let { start_date, name, platform } = this.form;
      if (!name) {
        this.$message.error("请输入论文标题");
      } else if (!start_date) {
        this.$message.error("请选择发表时间");
      } else if (!platform) {
        this.$message.error("请输入发表平台");
      } else return true;
      return false;
    },
    verifiPublic() {
      //公益
      let { start_date, name, platform } = this.form;
      if (!start_date) {
        this.$message.error("请选择时间");
      } else if (!name) {
        this.$message.error("请输入活动名称");
      } else if (!platform) {
        this.$message.error("请输入活动主办方");
      } else return true;
      return false;
    },
    async setTex(type) {
      //设置介绍
      if (type == 1) {
        this.edit.synopsis = true;
        this.setform.raw_synopsis = this.setform.synopsis;
      } else {
        this.edit.expertise = true;
        this.setform.raw_expertise = this.setform.expertise;
      }
    },
    async postUpdateSynopsis(type) {
      //修改   1简历/2专长
      let { expertise, synopsis } = this.setform;
      if (!expertise && type == 2) {
        this.$message.error("专长不能为空!");
        return;
      } else if (!synopsis && type == 1) {
        this.$message.error("简介不能为空");
        return;
      }
      // uni.showLoading({title:'提交中'})
      let { code } = await this.$userApi.postUpdateSynopsis({
        expertise,
        synopsis,
      });
      // uni.hideLoading();
      if (code == 200) {
        this.edit.expertise = false;
        this.edit.synopsis = false;
        this.$message({ message: "修改成功", type: "success" });
      }
    },
    handleChange(e) {
      this.departmentList.forEach((v) => {
        if (v.id == e[0]) {
          v.children.forEach((v2) => {
            if (v2.id == e[1]) {
              this.form.platform = v.name + "->" + v2.name;
            }
          });
        }
      });
    },
    changeTime(e) {
      this.form.start_date = e[0]; //开始时间
      let currentDate = this.getCurrentDate();
      if (e[1] == currentDate) {
        this.form.end_date = "至今";
      } else {
        this.form.end_date = e[1];
      }
    },

    empty() {
      this.form = {
        expertise: this.expertise, //个人简介
        raw_expertise: "",
        synopsis: this.synopsis, //个人专业
        raw_synopsis: "",

        start_date: "", //开始时间
        end_date: "", //结束时间
        name: "", //内容
        department_one_id: 0,
        department_two_id: 0,
        platform: "",
        images: "",
      };
    },
    setInfo() {
      if (!this.userInfo.info) this.userInfo.info = {};
      //个人简历
      this.setform.expertise = this.userInfo.info.synopsis;
      //个人专长
      this.setform.synopsis = this.userInfo.info.expertise;
      //职业信息
      // this.form.jobInformation = this.userInfo[1]
      //科研贡献
      // this.form.scientificResearchContribution = this.userInfo[2]
      //学术论文
      // this.form.academicPapers = this.userInfo[3]
      //公益活动
      // this.form.charitableActivities = this.userInfo[4]
      this.fen1 = `学术视频（${this.userInfo.science_video_count.toFixed(
        2
      )}）分`;
      this.fen2 = `指南解析（${this.userInfo.science_terms_count.toFixed(
        2
      )}）分`;
      this.fen3 = `病例分享（${this.userInfo.science_case_count.toFixed(
        2
      )}）分`;
      this.fen4 = `问答（${this.userInfo.science_problem_count.toFixed(2)}）分`;
      // this.fen5 = `说说（${this.userInfo.science_say_count}）条`;
      this.fen6 = `发布论文（${this.userInfo.science_paper_count.toFixed(
        2
      )}）分`;
      this.fensum = (
        this.userInfo.science_case_count +
        this.userInfo.science_problem_count +
        this.userInfo.science_video_count +
        this.userInfo.science_terms_count +
        this.userInfo.science_paper_count
      ).toFixed(2);
      this.$forceUpdate();
    },

    // 获取当前日期
    getCurrentDate(currentTime = new Date()) {
      let year = currentTime.getFullYear();
      let month = currentTime.getMonth() + 1;
      let day = currentTime.getDate();
      let currentDate =
        year + "-" + this.fillingZero(month) + "-" + this.fillingZero(day);
      return currentDate;
    },
    // 补0
    fillingZero(val) {
      return val > 9 ? val : "0" + val;
    },
  },

  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

/deep/.available.today span {
  color: #fff;
  &::after {
    content: "至今";
    font-size: 12px;
    color: $--themeColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
/deep/.end-date.today span {
  color: $--themeColor !important;
  &::after {
    color: #fff;
  }
}

/deep/.el-input__inner {
  border: none;
}
.page {
  background-color: #f2f2f2;
  > .header {
    height: 300px;
    object-fit: cover;
  }
  > header {
    height: 160px;
    background-color: white;
    box-sizing: border-box;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    > section {
      flex: 1;
      display: flex;
      > .avatar {
        position: absolute;
        left: 0;
        top: -75px;
        background-color: white;
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        border: 2px solid white;
        border-radius: 200px;
        object-fit: cover;
        > img {
          width: 200px;
          height: 200px;
          border-radius: 50%;
        }
        .jia_v {
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
      > .info {
        margin-left: 240px;
        display: flex;
        flex-direction: column;
        > .name {
          margin: 10px 0;
          display: flex;
          font-size: 36px;
          color: #333333;
          > .el-button {
            margin-left: 20px;
            border-color: $--themeColor;
            color: $--themeColor;
            font-size: 18px;
            height: 36px;
            padding: 0 19px;
            &:hover {
              background-color: white;
            }
            &:focus {
              background-color: white;
            }
          }
        }
        > span {
          font-size: 18px;
          line-height: 32px;
          color: #333333;
        }
      }
      > .btn {
        margin-left: auto;
        margin-top: auto;
        display: flex;
        .hui-btn {
          background: #f2f2f2;
          border-radius: 5px;
          color: #8f8f8f;
          width: 113px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          margin-left: 20px;
          cursor: pointer;
        }
        .el-button {
          margin-left: 20px;
          font-size: 18px;
          height: 36px;
          padding: 0 19px;
          &.edit {
            border-color: #333333;
            color: #333333;
            &:hover {
              background-color: white;
            }
            &:focus {
              background-color: white;
            }
          }

          &.authenticate {
            background-color: $--themeColor;
            border-color: $--themeColor;
            color: white;
            &:hover {
              background-color: $--themeColor;
            }
            &:focus {
              background-color: $--themeColor;
            }
          }
        }
      }
    }
  }
  > section {
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 30px 30px 25px;
    &::after {
      position: absolute;
      left: 0;
      top: 30px;
      height: 35px;
      width: 5px;
      content: "";
      display: block;
      background-color: $--themeColor;
    }
    > header {
      box-sizing: border-box;
      height: 35px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      > .title {
        font-size: 24px;
        color: #333333;
      }
      > .iconfont {
        margin-left: auto;
        font-size: 20px;
        cursor: pointer;
      }
    }
    ::v-deep {
      .el-textarea {
        textarea {
          background-color: transparent;
          border: none;
          border-radius: 0;
          box-sizing: border-box;
          padding: 10px;
          font-size: 18px;
          color: #333333;
        }
        &.edit {
          textarea {
            background-color: #f2f2f2;
            border-radius: 0;
            border-color: #d9d9d9;
          }
        }
        &.read {
          textarea {
            // background-color: #f2f2f2;
            border-radius: 0;
          }
        }
      }
      .el-collapse {
        border: none;
        .el-collapse-item__header {
          font-size: 18px;
          border: none;
          .el-collapse-item__arrow {
            margin-left: 10px;
          }
        }
        .el-collapse-item__wrap {
          border: none;

          .el-collapse-item__content {
            padding: 0;
            font-size: 18px;
            color: #8f8f8f;
            line-height: 30px;
          }
        }
      }
    }
    > .textList {
      display: flex;
      flex-direction: column;
      > .list {
        padding-left: 5px;
        font-size: 18px;
        line-height: 42px;
        color: #333333;
        border: 1px solid #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          box-sizing: border-box;
          background: #f2f2f2;
          border: 1px solid #d9d9d9;
          // transition: all 1s linear;
          .right-icon {
            display: block;
            width: max-content !important;
          }
        }
        .list-comuu {
          display: flex;
          > .date {
            display: flex;
            flex-direction: column;
            margin-right: 25px;
            > .month {
              font-size: 30px;
              color: #333333;
              font-weight: bold;
            }
            > .year {
              font-size: 18px;
              color: #8f8f8f;
            }
          }
          > img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
            object-fit: cover;
            margin-right: 20px;
          }
          > .content {
            display: flex;
            flex-direction: column;
            font-size: 18px;
            height: 80px;
            > .title {
              color: #333333;
            }
            > .sponsor {
              margin-top: auto;
              color: #8f8f8f;
            }
          }
        }
        .remark {
          color: #8f8f8f;
        }
        .right-icon {
          display: none;
          .iconfont {
            cursor: pointer;
            padding: 5px;
            margin-right: 15px;
            color: #333;
            font-size: 20px;
          }
        }
      }
      .list2 {
        font-size: 18px;
        line-height: 42px;
        color: #333333;
        display: flex;
      }
    }
    > .academicPapers {
      display: flex;
      flex-direction: column;
      > .list {
        font-size: 18px;
        line-height: 30px;
        margin-top: 20px;
        color: #333333;
        display: flex;
        flex-direction: column;
        &:first-child {
          margin-top: 0;
        }
        > .remark {
          color: #8f8f8f;
        }
      }
    }
    > .charitableActivities {
      display: flex;
      flex-direction: column;
      > .list {
        margin-top: 30px;
        display: flex;
        align-items: center;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    > .btn {
      margin-top: 20px;
      display: flex;
      > .el-button {
        margin-right: 20px;
        font-size: 18px;
        height: 46px;
        padding: 0 20px;
        border-radius: 6px;
        &.cancel {
          border-color: #333333;
          color: #333333;
          &:hover {
            background-color: white;
          }
          &:focus {
            background-color: white;
          }
        }
        &.confirm {
          background-color: $--themeColor;
          border-color: $--themeColor;
          color: white;
          &:hover {
            background-color: $--themeColor;
          }
          &:focus {
            background-color: $--themeColor;
          }
        }
      }
    }
    > .add {
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      .avatar-uploader-icon {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 78px;
        text-align: center;
        input {
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          width: 100%;
          height: 100%;
        }
        &:hover {
          border-color: #409eff;
        }
      }
      .avatar {
        width: 78px;
        height: 78px;
        display: block;
      }
      * {
        margin-left: 30px;
        &:first-child {
          margin-left: 0;
        }
      }
      > .el-button {
        margin-left: 20px;
        font-size: 18px;
        height: 46px;
        padding: 0 20px;
        border-radius: 6px;
        &.cancel {
          border-color: #333333;
          color: #333333;
          &:hover {
            background-color: white;
          }
          &:focus {
            background-color: white;
          }
        }
        &.confirm {
          background-color: $--themeColor;
          border-color: $--themeColor;
          color: white;
          &:hover {
            background-color: $--themeColor;
          }
          &:focus {
            background-color: $--themeColor;
          }
        }
      }
      ::v-deep {
        .el-date-editor {
          width: 310px;
          height: 46px;
          border-radius: 0;
          display: flex;
          align-items: center;
          &:focus {
            border-color: $--themeColor;
            outline: 0;
          }
          .el-input__icon {
            display: none;
          }
          .el-range-separator {
            line-height: 38px;
          }
        }
        .el-input {
          width: 240px;
          box-sizing: border-box;
          height: 46px;
          background-color: white;
          border-radius: 0;
          border: 1px solid #d9d9d9;
        }
      }
    }
  }
}
.list-ko {
  font-size: 18px;
  line-height: 42px;
  color: #949393;
}
</style>
